.container {
  .box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    background: #fff;
    width: 100%;
    position: relative;
    overflow: hidden;
    text-align: center;
    height: 100%;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  .buttonStyle {
    position: relative;
    border-radius: 5px;
    outline: none;
    text-decoration: none;
    background-color: #1f83c6;
    color: white;
    padding: 5px 10px 5px 15px;
    border: none;
    width: 120px;
    display: flex;

    &:hover {
      background-color: white;
      color: #adc98f;
    }
  }

  .box:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 0px;
    width: 0px;
    height: 100%;
    border-radius: 20px;
    box-shadow: inset 0 0 25px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
    background-image: linear-gradient(to right, #3c70a4 0%, #64b2cd 100%);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  .box:hover:before {
    width: 100%;
  }

  .box:hover .image-wrapper {
    padding: 0;
  }

  .box:hover .box-desc {
    color: #fff;
  }

  .box:hover .social li a {
    background: #fff;
    background-image: none;
    color: #adc98f;
  }

  .box:hover .social li a:hover {
    background: #1d1d1d;
    color: #fff;
  }

  .image-wrapper {
    position: relative;
    max-width: 180px;
    max-height: 180px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 50%;
    padding: 15px;
    transition: all 0.5s ease;
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  .image-wrapper img {
    border-radius: 50%;
    width: 100%;
    height: auto;
    transition: all 500ms ease;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  .box-desc {
    position: relative;
  }

  ul.social li a {
    background-image: none !important;
    line-height: 36px;
    border-radius: 50%;
    color: #1f83c6;
    flex-direction: column;
    transition: all 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
