.table-responsive {
  // border: 1px solid #c4c4c4;

  th,
  td {
    vertical-align: middle;
    font-size: 14px;
  }

  .tableRow {
    background: #1f83c6;
    border-radius: 4px 4px 0px 0px;
    color: #ffffff;
  }
}
.projects_img {
  position: absolute;
  width: 200px;
  left: 0;
  top: -39px;
}
