.cookies-accept {
  background-color: #65a856 !important;
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 14px;
  width: 100vw;
  z-index: 9999;
}
.cookies-accept h5 {
  font-size: 15px;
}
.cookies-accept a {
  color: #fff !important;
}
.cookies-accept a.btn-outline-light:hover {
  color: #1c2158 !important;
}
.cookies-accept a.btn-light {
  color: #1c2158 !important;
}
.cookies-accept a.btn {
  padding: 0.5rem 1rem;
}
.cookies-accept a.btn-light {
  box-shadow: unset !important;
}
.cookies-accept {
  padding-top: 2px;
  padding-bottom: 2px;
}
.radius-50 {
  border-radius: 50px;
  font-size: 15px;
}
@media only screen and (max-device-width: 600px) {
  .radius-50 {
    font-size: 12px;
  }
  .cookies-accept h5 {
    font-size: 12px;
  }
  .cookies-accept {
    font-size: 12px;
  }
}
