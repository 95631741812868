.blogContainer {
  .blogs_breadcrumb {
    background-image: url(../../assets/img/blogs/blog_banner.jpg);
    position: relative;
    background-size: cover;
    height: 60vh;
    // margin-top: -5%;
    z-index: -1;

    .breadcrumb_text {
      bottom: 3%;
      // transform: translate(-50%, -50%);
      left: 50%;
      right: -20%;
      position: absolute;
      color: #fff;
      text-align: center;

      .breadcrumb_title {
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  }

  .container {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(3, 380px);
  }

  .blogText {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #454545;
    padding: 20px;
  }

  .blogContainer {
    background: #ffffff;
    border: 1px solid #bbb1b1;
    width: fit-content;
    border-radius: 5px;
    height: 100%;
  }
  .textContainer {
    padding: 20px;
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .blogHeading {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-transform: capitalize;
    color: #454545;
  }

  .blogDiscreption {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: #454545;
  }

  .date {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.02em;
    color: #747474;
  }

  .buttonContainer {
    button {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #1f83c6;
      text-decoration: none;
      outline: none;
      border: 0;
      background-color: transparent;
    }
  }

  /* When the browser is at least 600px and above */
  @media screen and (max-width: 600px) {
    .container {
      grid-template-columns: repeat(1, 380px);
    }
  }
}
