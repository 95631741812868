.life_breadcrumb {
  background-image: url(../../assets/img/lifeayana-banner.png);
  position: relative;
  background-size: cover;
  height: 60vh;
  // margin-top: -9%;
  z-index: -1;

  .breadcrumb_text {
    bottom: 3%;
    transform: translate(-50%, -50%);
    left: 50%;
    right: -20%;
    position: absolute;
    color: #fff;
    text-align: center;

    .breadcrumb_title {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

.rewardParaText {
  margin: auto;
  max-width: 60%;
  width: 100%;
}

.lifeProjectHeading {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  text-transform: uppercase;
  color: #454545;
}

.rewardTextHeading {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  text-transform: uppercase;
  color: #ffffff;
}

.funTextHeading {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  text-transform: uppercase;
  color: #454545;
}

.ayana_life_corner_img {
  width: 17%;
  position: absolute;
  right: 0;
  top: 10px;
  transform: translate(0%, 0%);
  z-index: -1;
}

.life_ayana_main {
  .ayana_life_right {
    padding: 3%;

    .ayana_right_big_text {
      font-size: 28px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .ayana_life_corner_img {
    display: none;
  }
}

.ayana_life_slider_breadcrumb {
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
  .carousel-indicators {
    right: -90% !important;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    &::after {
      content: "" !important;
    }
  }
}
.life_at_ayana,
.life_at_project_site {
  .carousel-inner {
    width: 97%;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: 5%;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    filter: invert(1);
    &::after {
      content: "" !important;
    }
  }
}

.scrollable_contents {
  height: 200px;
  overflow-y: scroll;
  padding: 10px;
}
