.leadershipDetailscontainer {
  .backButtonLeadership {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-left: 10%;
    margin-top: 5%;

    button {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #1f83c6;
      text-decoration: none;
      outline: none;
      border: 0;

      background-color: transparent;
    }
  }
  .aboutLeaderDesc {
    p {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      content: #454545;
    }
  }
}
