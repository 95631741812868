.blogDetailsContainer {
  .blogs_breadcrumb {
    background-image: url(../../assets/img/blogs/blog_banner.jpg);
    position: relative;
    background-size: cover;
    height: 60vh;
    // margin-top: -9%;
    z-index: -1;

    .breadcrumb_text {
      bottom: 3%;
      left: 50%;
      right: -20%;
      position: absolute;
      color: #fff;
      text-align: center;

      .breadcrumb_title {
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 500;
      }
    }
  }

  .goBackButtonContainer {
    button {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #1f83c6;
      text-decoration: none;
      outline: none;
      border: 0;
      background-color: transparent;
    }
  }

  .blogcontainer {
    .blogHead {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 50px;
      letter-spacing: 0.02em;
      color: #111111;
    }
    .blogDesc {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 29px;
      /* or 161% */

      text-align: justify;
      letter-spacing: 0.02em;
      text-transform: lowercase;

      color: #111111;
    }
    .readNextContainer {
      background: #f2f2f2;
      width: fit-content;

      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 29px;
        letter-spacing: 0.02em;
        color: #000000;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 21px;
        letter-spacing: 0.02em;
        color: #111111;
      }
    }
    .shareContainer {
      background: #f2f2f2;
      width: 83%;
      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 29px;
        letter-spacing: 0.02em;
        color: #000000;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 21px;
        letter-spacing: 0.02em;
        color: #111111;
      }
    }
  }
  .placeholderContainer {
    input {
      padding: 10px;
      width: 82%;
      outline: none;
      text-decoration: none;
      border: 1px solid #747474;
      border-radius: 4px;
    }
  }
  .searchIcon {
    position: absolute;
    left: 88%;
    cursor: pointer;
  }
}

.blogcontainer .blog_detail_img {
  width: 100% !important;
}
.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
