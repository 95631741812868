.homeSliderContainer {
  display: inline-block;
  margin-left: 10px;
  margin-right: 30px;
  padding-top: 20px;
  padding-bottom: 10px;

  .cardContainer {
    margin-left: 20px;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  .jobTitle {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: #454545;
  }

  .jobLocation {
    display: flex;
    align-items: center;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #747474;
  }

  .jobButton {
    background: rgba(0, 135, 217, 0.14);
    border-radius: 20px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #747474;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 2px 20px 2px 20px;
    outline: none;
  }

  .applyButton {
    background: #1f83c6;
    border: 1px solid #1f83c6;
    border-radius: 4px;
    color: white;
    font-family: "Kumbh Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    padding: 10px 20px 10px 20px;
  }
}
