.blogImgeContainer {
  width: 100%;
  object-fit: cover;
  height: auto;
}

.textContainerBlog {
  p {
    font-size: 12px !important;
  }
}
