.csr_breadcrumb {
  background-image: url(../../assets/img/csr-banner.jpg);
  position: relative;
  background-size: cover;
  height: 60vh;
  // margin-top: -9%;
  z-index: -1;

  .breadcrumb_text {
    bottom: 3%;
    transform: translate(-50%, -50%);
    left: 50%;
    right: -20%;
    position: absolute;
    color: #fff;
    text-align: center;

    .breadcrumb_title {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

.csrList {
  li {
    position: relative;
    padding-left: 15px;
  }

  li::before {
    font-size: 16px;
    position: absolute;
    font-family: FontAwesome;
    top: 5px;
    left: -10px;
  }
}

.imageCsr {
  .btnVisitMore {
    display: flex;
    width: 26%;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    background-color: red;
    color: red;
  }
}

.text_blue {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #1f83c6;
}

.csImg {
  width: 100%;
  height: 600px;
  object-fit: cover;
}

.csImg3 {
  width: 100%;
  height: 360px;
  object-fit: cover;
}

.csImg4 {
  width: 100%;
  height: 280px;
  object-fit: cover;
}

.csImg5 {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.csImg6 {
  width: 100%;
  height: 700px;
  object-fit: cover;
}

.csImg7 {
  width: 100%;
  height: 600px;
  object-fit: cover;
}

.csImg8 {
  width: 100%;
  height: 550px;
  object-fit: cover;
}

@media only screen and (max-device-width: 600px) {
  .csImg {
    margin-left: auto;
  }
}

.projectPara {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  // text-transform: capitalize;
  color: #454545;
}

.projectdec {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #78aa42;
}

.paraCsr {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
  color: #454545;
}
