// .gridContainer {
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
//   grid-gap: 20px;
// }

.iframe-height{
    height: 650px;
}


$small: 480px;
$medium: 900px;

.iframe-height {

  @media screen and (max-width: $small) {
    height: 800px;
  }

}
