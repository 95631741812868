@media only screen and (max-device-width: 600px) {
  .buttonTabs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    color: whitesmoke;
  }
}

.blue_text {
  color: #1f83c6;
}

.offerings_tab_section {
  .nav-link.nav-link.active {
    border: none;
    border-bottom: 2px solid #a4c680;
    color: #1f83c6;
    font-weight: 500;
  }

  .nav-tabs {
    justify-content: space-evenly;

    position: sticky;
    top: 62px;
    z-index: 99;
    background: white;
    @media only screen and (max-device-width: 600px) {
      position: relative;
      top: auto;
    }

    .nav-link {
      font-size: 17px;
      padding: 14px 16px 15px;
      color: #1f83c6;

      &.nav-link:hover {
        color: #1f83c6;
      }

      @media only screen and (max-width: 1400px) {
        font-size: 16px;
        padding: 14px 17px 15px;
      }

      text-transform: none;
    }
  }

  .offerings_tab_box_shadow {
    box-shadow: 2px 0px 2px 2px #d2cece;
    background: #fff;
    border-radius: 5px;
    padding-left: 10px;
    padding-top: 10px;
  }

  .offering_tab_right {
    padding-left: 2%;
    padding-top: 10%;
  }

  .offering_tab_img {
    animation: fadeinleft 0.8s;
  }

  @keyframes fadeinleft {
    from {
      opacity: 0;
      transform: translateX(-40px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

// @media only screen and (max-device-width: 600px) {
//   .buttonTabs {}
// }

.offerings_tab_section .nav-tabs {
  justify-content: normal;
}

.offerings_tab_block {
  @media only screen and (max-device-width: 600px) {
    .nav-fill .nav-item .nav-link,
    .nav-justified .nav-item .nav-link {
      width: -webkit-fill-available;
    }
  }
}
