.media_breadcrumb {
  background-image: url(../../assets/img/media-banner.jpg);
  position: relative;
  background-size: cover;
  height: 60vh;
  // margin-top: -9%;
  z-index: -1;

  .breadcrumb_text {
    bottom: 3%;
    transform: translate(-50%, -50%);
    left: 50%;
    right: -20%;
    position: absolute;
    color: #fff;
    text-align: center;

    .breadcrumb_title {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

.media {
  .blue_text {
    color: #1f83c6;
  }

  .news_know_more {
    font-weight: 500;
  }

  .media_shadow_box {
    box-shadow: 0px 0px 3px 1px #b5b5b5;
    padding: 3%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a {
      color: #1f83c6;
    }

    .pdf_link {
      font-weight: 500;
      color: #1f83c6;
      text-decoration: none;
    }
  }
}

.latestNewsCard {
  gap: 20px;
}

@media only screen and (max-device-width: 600px) {
  .brandedAssetsContainer {
    gap: 20px;
  }

  .media {
    .media_shadow_box {
      text-align: center;
    }
  }
}
