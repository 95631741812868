.infographics_container {
  width: 100%;
  // max-width: 1464px;
  .inforgraphicContainer {
    align-items: start;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, 323px);
    justify-content: center;

    .test {
      position: absolute;
      bottom: 0;
      z-index: 2;

      p {
        line-height: 24px;
        color: #ffffff;
        text-align: left;
        margin-left: 20px;
        font-weight: 500;
        font-size: 15px;
      }
    }

    .infographicCardImage {
      width: 100%;
      height: auto;
      transition: 1s all;
    }

    .infographicCard {
      position: relative;
      overflow: hidden;

      .infographicCard:hover:before {
        content: "";
        background: #1f83c6;
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0.3;
        z-index: 1;
      }

      &:hover {
        &:before {
          content: "";
          background: #1f83c6;
          position: absolute;
          height: 100%;
          width: 100%;
          opacity: 0.3;
          z-index: 1;
        }

        img {
          transform: scale(1.2);
        }
      }

      & .test p {
        opacity: 0;
        transition: 1s all;
        transform: translateY(15px);
      }

      &:hover .test p {
        opacity: 1;
        transform: rotate3d(0, 0, 0, 45deg);
      }

      &:hover {
        overflow: hidden;
      }

      &:hover img {
        transform: scale(1.2);
      }

      .text {
        color: white;
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
        text-align: left;
        margin-left: 20px;
      }

      .infographicHeadingText {
        font-style: normal;
        margin-left: 20px;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 50px;
      }
    }
  }
}
