.partners_breadcrumb {
  background-image: url(../../assets/img/partnership-banner.png);
  position: relative;
  background-size: cover;
  height: 60vh;
  // margin-top: -9%;
  z-index: -1;

  .breadcrumb_text {
    bottom: 3%;
    transform: translate(-50%, -50%);
    left: 50%;
    right: -20%;
    position: absolute;
    color: #fff; //darsh
    text-align: center;

    .breadcrumb_title {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

.partners {
  .partners_right {
    padding-left: 2%;
  }

  .partner_shadow_box {
    box-shadow: 0px 0px 3px 1px #b5b5b5;
    height: 100%;
    border-radius: 5px;
  }

  .partnerCardText {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #454545;
  }

  .knowMoreLink {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #1f83c6;
  }
}
