.contact_breadcrumb {
  background-image: url(../../assets/img/contact-banner.png);
  position: relative;
  background-size: cover;
  height: 60vh;
  // margin-top: -9%;
  z-index: -1;
  &:before {
    content: "";
    background: rgb(15 16 16 / 20%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .breadcrumb_text {
    bottom: 3%;
    transform: translate(-50%, -50%);
    left: 50%;
    right: -20%;
    position: absolute;
    color: #fff;
    text-align: center;

    .breadcrumb_title {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

.locationText {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;

  /* or 200% */

  text-align: center;

  color: #454545;

  mix-blend-mode: normal;
  opacity: 0.8;
}

.fa-location-dot {
  font-size: 32px !important;
  color: #1f83c6 !important;
}
.fa-envelope {
  font-size: 32px !important;
  color: #1f83c6 !important;
}
.formContainer {
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.23);
  padding: 23px;
  height: fit-content;
}

.cardContainer {
  display: flex;
  margin-right: 20px;
  height: auto;
  margin-top: 0px;
}

a i {
  transition: all 0.3s linear;
}

a:hover i {
  transform: scale(1.4);
  -webkit-transform: scale(1.4);
  -moz-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
}

.mapContainer {
  width: 100% !important;
}

.select_dropdown {
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
    no-repeat right;
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
}
.contact_mails {
  list-style: none;
}
