.quality_breadcrumb {
  background-image: url(../../assets/img/quality-banner.png);
  position: relative;
  background-size: cover;
  height: 60vh;
  // margin-top: -9%;
  z-index: -1;

  .breadcrumb_text {
    bottom: 3%;
    transform: translate(-50%, -50%);
    left: 50%;
    right: -20%;
    position: absolute;
    color: #fff;
    text-align: center;

    .breadcrumb_title {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

.quality_safety {
  .sustainability_shadow_box {
    box-shadow: 0px 0px 3px 1px #b5b5b5;
    padding: 2% 2% 1% 2%;
    background: #fff;
    border-radius: 5px;
  }

  .blue_text {
    color: #0087d9;
  }

  .quality_safety_right {
    .big_text {
      font-size: 28px;
    }
  }

  .arppl_list {
    li {
      margin-bottom: 1%;
    }
  }
}
