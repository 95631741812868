.sustainability_breadcrumb {
  position: relative;
  background-size: cover;
  height: 60vh;
  // margin-top: -9%;
  z-index: -1;

  .breadcrumb_text {
    bottom: 3%;
    // transform: translate(-50%, -50%);
    left: 50%;
    right: -20%;
    position: absolute;
    color: #fff;
    text-align: center;

    .breadcrumb_title {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

.sustainablity {
  .card-container {
    align-items: start;
    display: grid;
    grid-gap: 120px;
    grid-template-columns: repeat(auto-fit, 300px);
    justify-content: center;

    .card {
      background: transparent;
      // transition: all 500ms ease-in-out;
      color: white;
      padding: 20px;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      .cardHeading {
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        text-align: center;
        color: #454545;
        margin-top: 20px;
      }

      .cardBody {
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #454545;
        text-align: center;
      }

      .cardImage {
        width: 58px;
        height: 58px;
        position: absolute;
        left: -20px;
        top: -20px;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        -ms-border-radius: 8px;
        -o-border-radius: 8px;
      }
    }
  }

  @media only screen and (max-device-width: 600px) {
    .card-container {
      grid-gap: 38px;
      margin-top: 20px;

      .card {
        padding: 15px;
        padding-top: 10px;
        padding-left: 48px;
        text-align: left;
      }
    }

    .cardHeading {
      font-size: 18px;
      text-align: left;
      margin-top: 50px;
    }

    .cardBody {
      font-size: 16px;
      color: #454545;
      text-align: left;
      margin-bottom: 0rem;
    }
  }

  .planetProsperity {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-transform: uppercase;
    /* identical to box height, or 136% */
    color: #1f83c6;
  }

  .sustanibilityText {
    margin: auto;
    max-width: 80%;
    width: 100%;
  }

  .absolute_div {
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%);
  }

  .sustainability_shadow_box {
    box-shadow: 0px 0px 3px 1px #b5b5b5;
    padding: 2% 2% 1% 2%;
    background: #fff;
    border-radius: 5px;
  }

  .green_text {
    color: #7aa037;
  }

  .sustainability_right {
    p {
      text-align: center;
    }
  }

  .btn_blue_tnp {
    background: #1f83c6;
    font-size: 18px;
    width: 125%;
    color: #fff;
    text-transform: inherit;
    transition: 0.7s;

    &:hover {
      background-color: #3e8e41 !important;
      color: #fff !important;
    }
  }

  .bi_shadow_box {
    box-shadow: 0px 0px 3px 1px #b5b5b5;
    padding: 3% 0% 0% 3%;
    height: 100%;
    border-radius: 5px;

    p {
      font-weight: 600;
      margin-bottom: 0rem;
    }

    .pdf_link {
      font-weight: 500;
      text-decoration: none;
    }
  }

  .case-study-section {
    background: #F5F6F7;
    padding-bottom: 50px;
    h3 {
      color: #78aa42;
      font-family: "Work Sans", sans-serif;
      -webkit-font-smoothing: antialiased;
      font-weight: 600;
      font-size: 38px;

      @media screen and (max-width: 767px){
        font-size: 24px;
      }

      @media (min-width: 768px) and (max-width: 1199px){
        font-size: 30px;
      }
    }

    p{
      font-family: "Work Sans", sans-serif;
      font-size: 24px;
      font-weight: 500;
      @media screen and (max-width: 767px){
        font-size: 16px;
      }

      @media (min-width: 768px) and (max-width: 1199px){
        font-size: 18px;
      }
    }

    a{
      color: #447EBE;
      font-weight: 500;
      overflow-wrap: anywhere;
      font-size: 18px;
      @media screen and (max-width: 767px){
        font-size: 14px;
      }

      @media (min-width: 768px) and (max-width: 1199px){
        font-size: 16px;
      }
    }

    img{
      margin: auto;
      display: block;
      max-width: 80%;
      box-shadow: 0px 2px 8px 0px #20202040;
    }
  }
}

.sustainlibilityContainer {
  padding-left: 160px;
  padding-right: 160px;
}

@media only screen and (max-width: 600px) {
  .sustainlibilityContainer {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.peopleImg {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.bgGreen {
  background-color: #78aa42;
  p {
    font-size: 16px;
  }
}
