.submitButtonContact {
  background-color: #1f83c6;
  margin-top: 5px;
  display: flex;
  border: 1px solid #1f83c6;
  border-radius: 4px;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  padding: 15px 40px 15px 40px;
  text-transform: uppercase;
}

.preloaderbtnContact {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}

.preloaderbtnSpinContact {
  position: absolute;
  left: 25px;
}
