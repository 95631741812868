.vendorBanner {
  background-image: url(../../assets/img/vendor_banner.jpg);
  position: relative;
  background-size: cover;
  object-fit: cover;
  height: 50vh;
  margin-top: -9%;
  z-index: -1;
}

.space-40 {
  padding-top: 40px;
}

.vpgform {
  // padding: 10px 30px;

  .form-heading {
    text-align: center;

    h1 {
      font-weight: 600;
      font-size: 30px;
      text-transform: uppercase;
    }
  }

  #vr_form {
    .form-check-input {
      &:before {
        visibility: hidden;
      }

      &:after {
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      }
    }

    h2 {
      background: #0087d9;
      border-radius: 4px 4px 0px 0px;
      font-weight: 600;
      font-size: 18px;
      color: #ffffff;
      padding: 15px 20px;
    }

    input {
      border: none;
      box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
      border-radius: 4px;
      font-size: 15px;
      // padding: 20px 50px;
      // margin-left: 20px;
    }

    .form-check {
      line-height: 2;

      .form-check-input {
        border: 1px solid #0087d9;
        padding: inherit;
        border-radius: 50%;
        box-shadow: none;
        height: 28px;
        margin-right: 5px;
      }
    }

    .upload {
      display: flex;
      align-items: center;

      img {
        cursor: pointer;
      }

      p {
        margin-bottom: 0;
        margin-top: 0;
        font-weight: 400;
        font-size: 14px;
        color: #747474;
      }

      .upload-area {
        margin-right: 6px;
      }
    }

    .table-bordered > :not(caption) > * > * {
      border-width: 0 0px;
    }

    table {
      border: 1px solid #ddd;
    }

    .note {
      font-weight: 400;
      font-size: 14px;
      color: #747474;
      display: block;
      padding-top: 20px;

      b {
        color: #ff3c00;
      }
    }

    .yearview {
      background: #f2f2f2;
      box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
      border-radius: 4px;
      padding: 8px 10px;
      align-items: center;
    }

    p {
      // font-size: 14px;
      margin-top: 1rem;
    }
  }

  th {
    font-weight: 400;
    font-size: 15px;
    color: #454545;
  }

  thead {
    background: #f2f2f2;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px 4px 0px 0px;
    font-weight: 400;
    font-size: 15px;
  }

  #formFile {
    display: none;
  }
}

.preloadVenContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloaderbtnVendor {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}

.preloaderbtnSpinVendor {
  position: absolute;
  left: 10%;
  top:22%;
}

.preloadVenContainer .preloaderOverlay {
  display:block;
}

.vendor_phone_number_input {
  padding-left: 60px;
}

.vendor_phone_number_label {
  position: absolute;
  top: 0%;
  left: 0;
  background: #1f83c6;
  height: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 3.1% 2.2%;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}


.vendorSubmitButton {
  background: #1f83c6;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: end;
  border: 1px solid #1f83c6;
  border-radius: 4px;
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #f2f2f2;
  padding: 20px 45px 20px 45px;
  text-transform: uppercase;
}

.mobile-view {
  tr {
    @media only screen and (max-device-width: 600px) {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      margin-bottom: 10px;
      background: #f1f1f1;
    }
  }
}
