@import url("https://fonts.googleapis.com/css2?family=Anton&family=Antonio&family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=PT+Serif&family=Questrial&family=Qwigley&family=Raleway&family=Tiro+Gurmukhi&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Work+Sans:wght@100;200;300;400;500;600;700&display=swap");

.footer {
  //   background-image: url("../../assets/footer-background-image.png");
  //   background-position: center;
  //   width: 100%;
  padding-top: 50px;
  //   background-repeat: no-repeat;
  //   background-size: 100% 100%;
  //   height: auto;
  //   overflow: hidden;

  ul {
    list-style-type: none;
    font-size: 14px;
    padding-left: 0;
    color: #747474;

    @media only screen and (max-width: 1400px) {
      font-size: 13px;
    }
  }

  a {
    text-decoration: none;
    color: #747474;
  }

  .line_h_23 {
    line-height: 23px;
  }
}

@media only screen and (max-device-width: 600px) {
  .footer {
    padding-top: 0px;
  }
}

.footerParagraphs {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #747474;

  @media only screen and (max-width: 1400px) {
    font-size: 13px;
  }

  .address {
    padding-left: 2rem;
    position: relative;

    li:before {
      content: url("../../assets/img/map_icon.png");
      position: absolute;
      left: 0;
      top: 4px;
    }
  }

  .email {
    padding-left: 2rem;
    position: relative;

    li:before {
      content: url("../../assets/img/mail_icon.png");
      position: absolute;
      left: 0;
      top: 4px;
    }
  }

  .contact_no {
    padding-left: 2rem;
    position: relative;

    li:before {
      content: url("../../assets/img/phone_icon.png");
      position: absolute;
      left: 0;
      top: 4px;
    }
  }
}

.footerHeading {
  display: flex;
  flex-wrap: wrap;
  padding-top: 60px;
  justify-content: center;
  gap: 30px;
  font-family: "Work Sans";
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 35px;
  color: #1f83c6;
}

.socialIcons {
  color: #1f83c6;
}

.linksHeader {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 10px;
  color: #1f83c6;
  text-transform: uppercase;
}

hr {
  border: 1px solid #939292;
  width: 100%;
}

.footerText {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  // margin-top: 60px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  background-color: #0087d9;

  @media only screen and (max-width: 1400px) {
    font-size: 13px;
  }

  line-height: 35px;
  text-align: center;
  color: #fff;

  p {
    margin-bottom: 0rem;
  }
}

// sticky social icons
.sticky-container {
  padding: 0px;
  margin: 0px;
  position: fixed;
  right: -170px;
  top: 70%;
  width: 210px;
  z-index: 1100;
}

.sticky li {
  list-style-type: none;
  height: 43px;
  padding: 0px;
  margin: 0px 0px 1px 0px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
}

.sticky li img {
  float: left;
  margin: 5px 4px;
  margin-right: 5px;

  &:hover {
    animation: bellshake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    backface-visibility: hidden;
    transform-origin: top right;
  }
}

.footer-bottom{
  .footer-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin: auto;

    @media (min-width: 320px) and (max-width: 540px){
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@keyframes bellshake {
  0% {
    transform: rotate(0);
  }

  15% {
    transform: rotate(5deg);
  }

  30% {
    transform: rotate(-5deg);
  }

  45% {
    transform: rotate(4deg);
  }

  60% {
    transform: rotate(-4deg);
  }

  75% {
    transform: rotate(2deg);
  }

  85% {
    transform: rotate(-2deg);
  }

  92% {
    transform: rotate(1deg);
  }

  100% {
    transform: rotate(0);
  }
}

@media only screen and (max-width: 480px) {
  .footer {
    background-image: none;
  }
}
