.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  align-items: center;
  justify-content: flex-end;
  margin-right: 155px;
  gap: 5px;
}

.closeButton {
  display: flex;
  background-color: white;
  outline: none;
  text-decoration: none;
  border: none;
  margin-left: auto !important;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #0087d9;
}
.jobCardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 10px;
  padding-bottom: 10px;

  .cardContainer {
    padding: 30px;
    background: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    z-index: 2;
  }

  .jobTitle {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: #454545;
  }

  .jobLocation {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #747474;
  }

  .jobButton {
    background: rgba(0, 135, 217, 0.14);
    border-radius: 20px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #747474;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 2px 20px 2px 20px;
    outline: none;
  }

  .applyButton {
    background: #1f83c6;
    border: 1px solid #1f83c6;
    border-radius: 4px;
    color: white;
    font-family: "Kumbh Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    padding: 10px 20px 10px 20px;
  }

  // padding TRBL
}

@media only screen and (max-width: 736px) {
  .ReactModal__Content {
    width: 92% !important;
  }
}
