.career_breadcrumb {
  background-image: url(../../assets/img/careers-images/career-banner.jpg);
  position: relative;
  background-size: cover;
  height: 60vh;
  // margin-top: -9%;
  z-index: -1;

  .breadcrumb_text {
    bottom: 3%;
    transform: translate(-50%, -50%);
    left: 50%;
    right: -20%;
    position: absolute;
    color: #fff;
    text-align: center;

    .breadcrumb_title {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

.no_border {
  border: none !important;
}

.job_box_shadow {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 3px 2px #d8d8d8;
  border-radius: 5px;
}

.career_img1 {
  position: absolute;
  top: 0;
  width: 90px;
}
.career_img2 {
  position: absolute;
  bottom: -11px;
  right: 0;
  width: 108px;
  z-index: 1;
}
.job_drop_down {
  button {
    color: #454545;
  }

  &::before {
    position: absolute;
    background: #00000054;
    height: 13px;
    width: 1px;
    left: 0;
    top: 50%;
    content: "";
    transform: translate(-50%, -50%);
  }
}

.job_input_big {
  padding: 0.65rem 0.75rem !important;
  outline: none !important;
}

.job_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 100px !important;
  font-size: 15px;
}

.careerText {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #454545;
}

.placeholderText {
  font-size: 13px;
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);

  .sliderBackground {
    background-color: #5ab5ed;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }
}

.internship_sub_heading {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 32px;
  /* identical to box height, or 123% */

  color: #454545;
}

.careersContainer {
  align-items: start;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, 350px);
  justify-content: center;

  .test {
    position: absolute;
    bottom: 0;
    z-index: 2;

    p {
      line-height: 24px;
      color: #ffffff;
      text-align: left;
      margin-left: 20px;
      font-weight: 500;
      font-size: 15px;
    }
  }

  .cardImage {
    width: 100%;
    height: auto;
    transition: 1s all;
  }

  .careersCard {
    position: relative;
    overflow: hidden;

    .careersCard:hover:before {
      content: "";
      background: linear-gradient(180deg, rgba(41, 33, 48, 0) 0%, #292130 100%);
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0.3;
      z-index: 1;
    }

    &:hover {
      &:before {
        content: "";
        background: linear-gradient(
          180deg,
          rgba(41, 33, 48, 0) 0%,
          #292130 100%
        );
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0.3;
        z-index: 1;
      }

      img {
        transform: scale(1.2);
      }
    }

    & .test p {
      opacity: 0;
      transition: 1s all;
      transform: translateY(15px);
    }

    &:hover .test p {
      opacity: 1;
      transform: rotate3d(0, 0, 0, 45deg);
    }

    &:hover {
      overflow: hidden;
    }

    &:hover img {
      transform: scale(1.2);
    }

    .text {
      color: white;
      font-size: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-size: 14px;
      line-height: 24px;
      color: #ffffff;
      text-align: left;
      margin-left: 20px;
    }

    .careersHeadingText {
      font-style: normal;
      margin-left: 20px;
      font-weight: 600;
      font-size: 26px;
      line-height: 30px;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 50px;
    }
  }
}

@media only screen and (max-device-width: 480px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .career_text_container {
    text-align: center;
  }

  .placeholderText {
    margin-bottom: 2%;
  }

  .job_drop_down::before {
    background-color: transparent;
  }

  .input_text {
    text-align: center;
  }

  .search_btn_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    outline: none;
    margin-top: 5%;
    margin-bottom: 2%;
  }
}
.jobDescText {
  p {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #454545;
    padding-left: 50px;
    padding-right: 50px;
  }
}

.career_slider_breadcrumb {
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
  .carousel-indicators {
    right: -90% !important;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    &::after {
      content: "" !important;
    }
  }
}
