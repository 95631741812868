.offerings_breadcrumb {
  background-image: url(../../assets/img/offerings-banner.jpg);
  position: relative;
  background-size: cover;
  height: 60vh;
  // margin-top: -9%;
  z-index: -1;

  @media only screen and (max-width: 600px) {
    .intro_breadcrumb {
      background-image: url(../../assets/img/about-mb.png);
      width: 100%;
      height: 60vh;
      object-fit: cover;
    }
  }

  .breadcrumb_text {
    bottom: 3%;
    transform: translate(-50%, -50%);
    left: 50%;
    right: -20%;
    position: absolute;
    color: #fff;
    text-align: center;

    .breadcrumb_title {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}
