.buttonScrool {
  position: fixed;
  width: 100%;
  left: 94%;
  bottom: 40px;
  z-index: 1;
  cursor: pointer;
}

@media (max-width: 700px) {
  .buttonScrool {
    left: 87%;
    bottom: 20px
  }
}