.section {
  width: 90vw;
  margin: 5rem auto;
  max-width: 1970px;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
    padding: 0% !important;
  }
}

.icon {
  font-size: 3rem;
  color: #ba5d2c;
}

.section-center {
  margin: 0 auto;
  width: 100%;
  height: 450px;
  max-width: 80%;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
}

article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s linear;
}

article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}

article.lastSlide {
  transform: translateX(0%);
}

article.nextSlide {
  transform: translateX(100%);
}

.person-img {
  border-radius: 50%;
  margin-bottom: 1rem;
  width: 90px;
  height: 90px;
  object-fit: cover;
  // border: 4px solid #bcccdc;
  // box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

article h4 {
  font-size: 18px;
  margin-bottom: 0.25rem;
}

.text {
  max-width: 90%;
  margin: 0 auto;
  margin-top: 2rem;
  line-height: 2;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #454545;
}

.prev,
.next {
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
  background: #1f83c6;
  color: #fff;
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s linear;
}

.prev:hover,
.next:hover {
  background: #1f83c6;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

@media (min-width: 800px) {
  .text {
    max-width: 90%;
  }

  .prev,
  .next {
    width: 2rem;
    height: 2rem;
    // font-size: 1.5rem;
  }
}

@media only screen and (max-device-width: 480px) {
  .section-center {
    // height: 514px;
    overflow-y: scroll;
    max-width: 95%;
    .prev,
    .next {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 0.7rem;
    }
    .text {
      margin-top: 0%;
      font-size: 14px;
      line-height: inherit;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only */
  .section-center {
    overflow-y: scroll;
    max-width: 90%;
  }
}
