/* Spinner Styles */

.preloaderOverlay {
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preloaderContainer {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 3px solid rgba(12, 219, 57, 0.6);
  border-radius: 50%;
  border-top-color: #abb60e;
  -webkit-animation: spin 1s ease-in-out infinite;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
