.thankyou {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 150px;
  margin-left: 200px;
  margin-right: 200px;
  margin-top: 10px;
  margin-bottom: 70px;
  border-radius: 10px;
  background-color: rgb(233, 226, 226);

  h3 {
    color: #1f83c6;
  }
}

@media only screen and (max-width: 480px) {
  .thankyou {
    padding: 50px;
  margin-left: 20px;
  margin-right: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  }
}

