.applyNowContainer {
  margin-left: 100px;
  margin-right: 100px;

  .inputForm {
    background: #f2f2f2;
    border-radius: 4px;
    outline: none;
    height: 70px;
    width: 90%;
    margin: 5px 24px;
    border: none;

    input::placeholder {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 30px;
      color: #454545;
    }
  }

  .dropDown {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #454545;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
      no-repeat right #f2f2f2;
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 50%;
  }

  .submitButton {
    background: #1f83c6;
    margin-top: 5px;
    margin-left: 26px;
    display: flex;
    border: 1px solid #1f83c6;
    border-radius: 4px;
    color: white;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #f2f2f2;
    padding: 15px 40px 15px 40px;
    text-transform: uppercase;
  }

  .educationContainer {
    background-color: #f2f2f2;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    border: none;
    box-shadow: none;
  }

  .edcucationInput {
    width: 100%;
    height: 40px;
    outline: none;
  }

  label {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #454545;
  }

  .importDoc {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #0087d9;
  }
}

.imgUploadContainer {
  img {
    height: auto;
    width: 100px;
    object-fit: cover;
    cursor: pointer;
  }
}

@media screen and (max-width: 600px) {
  .applyNowContainer {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.custom-file input[type="file"] {
  display: none;
}

.preloaderbtn {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}

.preloaderbtnSpin {
  position: absolute;
  left: 50px;
}
