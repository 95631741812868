.suppliers_breadcrumb {
  background-image: url(../../assets/img/suppliers/suppliers_banner.jpg);
  position: relative;
  background-size: cover;
  height: 60vh;
  // margin-top: -9%;
  z-index: -1;

  .breadcrumb_text {
    bottom: 3%;
    transform: translate(-50%, -50%);
    left: 50%;
    right: -20%;
    position: absolute;
    color: #fff;
    text-align: center;

    .breadcrumb_title {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

.supplier {
  .font_32 {
    font-size: 32px;
  }
  .cloud_text {
    padding-left: 1%;
  }
  a {
    text-decoration: none;
  }
  .pdf_btn_logo {
    padding: 1%;
    color: #fff;
    border-radius: 5px 0px 0px 5px;
  }
  .pdf_btn_text {
    
    padding: 1%;
    font-weight: 500;
    border-radius: 0px 5px 5px 0px;
    p {
      color: #000 !important;
      margin-bottom: 0rem;
    }
  }
}
@media screen and (max-width: 500px) {
.pdf_link {
  width: 100%;
  margin-bottom: 1REM;
  background: #e3e3e3;
}
.custom_pdf{
  flex-wrap: wrap;
  width: 100%;
}
}