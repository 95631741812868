/* Spinner Styles */

.spinnerOverlay {
    height: 60vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinnerContainer {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(20, 180, 73, 0.6);
    border-radius: 50%;
    border-top-color: #15509c;
    -webkit-animation: spin 1s ease-in-out infinite;
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}