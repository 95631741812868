.App {
  overflow-x: hidden;
}

p {
  margin-bottom: 0;
}

a {
  text-decoration: none;
}
