.container {
  .descriptionContainer {
    background: #ffffff;
    box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.25);
    padding: 30px;

    .viewJob {
      font-family: "Kumbh Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #0087d9;
      border: none;
      text-decoration: none;
      outline: none;
      background: none;
      @media only screen and (max-device-width: 480px) {
        font-size: 14px;
      }
    }
  }

  .jobTitle {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-transform: uppercase;
    color: #454545;
    @media only screen and (max-device-width: 480px) {
      font-size: 18px;
    }
  }

  .closeButton {
    display: flex;
    background-color: white;
    outline: none;
    text-decoration: none;
    border: none;
    margin-left: auto !important;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #0087d9;
  }

  .locationContainer {
    p {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      color: #747474;
    }
  }

  .jobHighlights {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #454545;
  }

  .jobButton {
    background: rgba(0, 135, 217, 0.14);
    border-radius: 20px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #747474;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 2px 20px 2px 20px;
    outline: none;
  }

  .jobType {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    text-transform: uppercase;
    color: #747474;
  }

  .jobPoints {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #454545;
  }

  .jobResponsibilities {
    .jobHeading {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      color: #454545;
    }

    .jobSubheading {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      text-transform: uppercase;
      color: #454545;
    }

    ul {
      li {
        list-style: circle;
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #454545;
      }
    }
  }

  .aboutCompany {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    // line-height: 32px;
    color: #454545;
    text-align: left;
    @media only screen and (max-device-width: 480px) {
      font-size: 16px;
    }
  }

  .companyDescription {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    color: #454545;
  }

  .shareButton {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    cursor: pointer;

    P {
      font-family: "Work Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .css-ahj2mt-MuiTypography-root {
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    text-align: left !important;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #fff !important;
    font-family: "Work Sans" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 28px !important;
    text-transform: uppercase !important;
    background-color: #1976d2 !important;
    @media only screen and (max-width: 1290px) {
      font-size: 12px !important;
    }
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    border-right: 1px solid lightgray !important;
    @media only screen and (max-width: 1290px) {
      font-size: 12px;
    }
  }

  .css-heg063-MuiTabs-flexContainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-device-width: 480px) {
      flex-direction: column;
    }
  }

  .css-19kzrtu {
    padding: 24px;
    text-align: left !important;
  }

  .jobContainer {
    background: #ffffff;
    border-top: 1px solid #ebebeb;
    padding: 25px 0px 25px 0px;
  }
}
