$color_1: #999;
$color_2: hwb(0 47% 53%);
$color_3: #1f83c6;
$color_4: #fff;
$color_5: #1f83c6;
$color_6: orange;
$color_7: #333;
$color_8: #eee;
$font-family_1: "Open Sans", sans-serif;
$background-color_1: #1f83c6;
$background-color_2: #fff;
$background-color_3: rgba(255, 255, 255, 0.5);
$background-color_4: rgba(0, 0, 0, 0.5);
$background-color_5: #f9f9f9;
$background-color_6: #000;
$background-color_7: transparent;
$background-color_8: #1f83c6;
$background-color_9: #777;
$background-color_10: #999;

/* Body Styles */
/* Navigation Styles */
/* Menu Icons for Devices*/
/*
  
  .ic.menu:focus ~ .ic.close { opacity: 1.0; z-index : 21;  }
  .ic.close:focus { opacity: 0; }
  */
/* Main Menu for Handheld Devices  */
/* Main Menu for Desktop Devices  */
/* Sub Menu */
/* List Separator: Outer Border */
/* List Separator: Inner Border */
/* Drop Down/Up Arrow for Mega Menu */
.headerContainerNew {
  .navLogo {
    object-fit: cover;
    width: 315px;
    border-bottom: 2px solid #78aa42;
  }

  @media only screen and (max-device-width: 580px) {
    .navLogo {
      // width: 320px;
      height: 70px;
    }
  }

  .author {
    position: fixed;
    bottom: 15px;
    right: 15px;
    font-family: $font-family_1;
    font-size: 14px;
    color: $color_1;
    a {
      color: $color_2;
      text-decoration: none;
      outline: none;
      &:hover {
        color: $color_3;
      }
    }
  }
  header.dark {
    blockquote {
      color: $color_4;
      a {
        text-decoration: none;
        outline: none;
      }
    }
    background-color: $background-color_1;
    nav {
      // background-color: $background-color_3;
      display: flex;
      position: fixed;
      z-index: 1003;
      background-color: $background-color_1;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 70px;
      border-bottom: 2px solid #78aa42;
    }
  }
  header.light {
    blockquote {
      color: $color_5;
    }
    background-color: $background-color_2;
    nav {
      background-color: $background-color_4;
    }
  }

  // header {
  //   min-height: 40px;
  // }
  nav {
    position: relative;
  }
  ul.main-nav {
    list-style-type: none;
    padding: 0px;
    font-size: 0px;
    max-width: 1000px;
    margin: 0 auto;
    > li {
      display: inline-block;
      padding: 0;
      > a {
        display: block;
        padding: 20px 15px;
        position: relative;
        color: $color_4;
        font-size: 16px;
        font-weight: 400;
        box-sizing: border-box;
        text-decoration: none;
        cursor: pointer;
        @media only screen and (max-width: 1280px) {
          font-size: 14px;
          padding: 23px 12px;
        }
      }
      &:hover {
        > a {
          font-weight: 400;
          text-decoration: none;
        }
      }
      ul.sub-menu-lists {
        margin: 0px;
        padding: 0px;
        list-style-type: none;
        display: block;

        > li {
          padding: 5px;
          width: 100%;
          &:hover {
            background-color: #78aa42;
            color: white;
          }
          > a {
            font-size: 16px;
            text-decoration: none;
            color: inherit;
            display: block;
            width: 100%;
            @media only screen and (max-width: 1280px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .ic {
    position: fixed;
    cursor: pointer;
    display: inline-block;
    right: 25px;
    width: 32px;
    height: 24px;
    text-align: center;
    top: 0px;
    outline: none;
  }
  .ic.close {
    opacity: 0;
    font-size: 0px;
    font-weight: 300;
    color: $color_4;
    top: 8px;
    height: 40px;
    display: block;
    outline: none;
  }
  .ic.menu {
    top: 25px;
    z-index: 20;
    .line {
      height: 4px;
      width: 100%;
      display: block;
      margin-bottom: 6px;
    }
    .line-last-child {
      margin-bottom: 0px;
    }
  }
  .sub-menu-head {
    margin: 10px 0;
  }
  .banners-area {
    margin-top: 20px;
    padding-top: 15px;
    img {
      width: 150px;
    }
  }
  @media only screen and (max-width: 768px) {
    .sub-menu-head {
      color: $color_6;
      font-size: 16px;
    }
    .ic.menu {
      display: block;
      .line {
        -webkit-transition: all 0.4s ease 0s;
        -o-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center;
      }
      &:focus {
        .line {
          background-color: $background-color_2 !important;
          &:nth-child(1) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
            margin-top: -10px;
          }
          &:nth-child(3) {
            transform: translateY(15px);
            opacity: 0;
          }
        }
        outline: none;
        opacity: 1;
        ~ {
          .ic.close {
            opacity: 1;
            z-index: 21;
            outline: none;
          }
          .main-nav {
            width: 300px;
            background-color: $background-color_8;
            > * {
              opacity: 1;
            }
          }
          ul.main-nav {
            > li {
              > div.sub-menu-block {
                border-left: 0px solid #ccc;
                border-right: 0px solid #ccc;
                border-bottom: 0px solid #ccc;
                position: relative;
                visibility: visible;
                opacity: 1;
              }
            }
          }
        }
      }
      &:hover {
        opacity: 1;
      }
    }
    header.dark {
      .ic.menu {
        .line {
          background-color: $background-color_2;
        }
      }
    }
    header.light {
      .ic.menu {
        .line {
          background-color: $background-color_6;
        }
      }
    }
    nav {
      background-color: $background-color_7;
    }
    ul.main-nav {
      z-index: 2;
      padding: 50px 0;
      position: fixed;
      right: 0px;
      top: 0px;
      width: 0px;
      background-color: $background-color_8;
      height: 100%;
      overflow: auto;
      -webkit-transition-property: background, width;
      -moz-transition-property: background, width;
      -o-transition-property: background, width;
      transition-property: background, width;
      -webkit-transition-duration: 0.6s;
      -moz-transition-duration: 0.6s;
      -o-transition-duration: 0.6s;
      transition-duration: 0.6s;
      > * {
        -webkit-transition-property: opacity;
        -moz-transition-property: opacity;
        -o-transition-property: opacity;
        transition-property: opacity;
        -webkit-transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        transition-duration: 0.4s;
        opacity: 0;
      }
      > li {
        > a {
          &:after {
            display: none;
          }
          font-weight: 600;
        }
        &:first-child {
          border-radius: 0px;
        }
        display: block;
        border-bottom: 1px solid #444;
        ul.sub-menu-lists {
          > li {
            a {
              color: $color_8;
              font-size: 14px;
            }
          }
        }
        &:hover {
          background-color: $background-color_7;
          > a {
            color: $color_4;
            text-decoration: none;
            font-weight: 600;
          }
        }
      }
    }
    .sub-menu-block {
      padding: 0 30px;
    }
    .banners-area {
      padding-bottom: 0px;
      border-top: 1px solid #444;
      div {
        margin-bottom: 15px;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .ic.menu {
      display: none;
    }
    ul.main-nav {
      display: block;
      position: relative;
      > li {
        > div.sub-menu-block {
          visibility: hidden;
          background-color: $background-color_5;
          position: absolute;
          margin-top: 0px;
          width: 30%;
          color: $color_7;
          left: 10;
          border-radius: 3%;
          box-sizing: border-box;
          z-index: 3;
          font-size: 16px;
          border-left: 1px solid #ccc;
          border-right: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          opacity: 0;
          -webkit-transition: all 0.4s ease 0s;
          -o-transition: all 0.4s ease 0s;
          transition: all 0.4s ease 0s;
          -webkit-transform: rotateX(90deg);
          -moz-transform: rotateX(90deg);
          -ms-transform: rotateX(90deg);
          transform: rotateX(90deg);
          -webkit-transform-origin: top center;
          -ms-transform-origin: top center;
          transform-origin: top center;
          > * {
            -webkit-transition-property: opacity;
            -moz-transition-property: opacity;
            -o-transition-property: opacity;
            transition-property: opacity;
            -webkit-transition-duration: 0.4s;
            -moz-transition-duration: 0.4s;
            -o-transition-duration: 0.4s;
            transition-duration: 0.4s;
            opacity: 0;
          }
        }
        &:hover {
          > div.sub-menu-block {
            background-color: $background-color_5;
            visibility: visible;
            opacity: 1;
            -webkit-transform: rotateX(0deg);
            -moz-transform: rotateX(0deg);
            -ms-transform: rotateX(0deg);
            transform: rotateX(0deg);

            > * {
              opacity: 1;
            }
          }
        }

        > a.mega-menu {
          > span {
            display: block;
            vertical-align: middle;
            &:after {
              width: 0;
              height: 0;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              border-top: 5px solid #fff;
              content: "";
              background-color: $background-color_7;
              display: inline-block;
              margin-left: 10px;

              vertical-align: middle;
            }
          }
        }
      }
    }
    .sub-menu-block {
      padding: 15px;
    }
    .sub-menu-head {
      font-size: 16px;
    }
    header.dark {
      ul.main-nav {
        > li {
          > a {
            // border-right: 1px solid #bbb;
            &:after {
              background-color: $background-color_9;
            }
          }
        }
      }
    }
    header.light {
      ul.main-nav {
        > li {
          > a {
            border-right: 1px solid #666;
            &:after {
              background-color: $background-color_10;
            }
          }
        }
      }
    }
    .banners-area {
      border-top: 1px solid #ccc;
    }
  }
}
@media only screen and (min-width: 992px) {
  .headerContainerNew .ic.menu {
    display: none;
  }
  .headerContainerNew ul.main-nav {
    display: block;
    position: relative;
  }
  .headerContainerNew ul.main-nav > li > a.mega-menu > span {
    display: block !important;
    vertical-align: middle !important;
  }
}
@media only screen and (max-width: 991px) {
  .headerContainerNew .ic.menu {
    display: block;
  }
  .headerContainerNew ul.main-nav > li:first-child {
    border-radius: 0px;
  }
  .headerContainerNew ul.main-nav > li {
    display: block;
    border-bottom: 1px solid #444;
  }
  .headerContainerNew ul.main-nav > * {
    transition-property: opacity;
    transition-duration: 0.4s;
    opacity: 0;
  }
  .headerContainerNew ul.main-nav > li > a {
    font-weight: 600;
  }

  .headerContainerNew header.dark .ic.menu .line {
    background-color: #fff;
  }
  .headerContainerNew .ic.menu .line {
    transition: all 0.4s ease 0s;
    transform: rotate(0deg);
    transform-origin: center center;
  }
  .headerContainerNew .ic.menu:focus {
    outline: none;
    opacity: 1;
  }
  .headerContainerNew .ic.menu:hover {
    opacity: 1;
  }
  .headerContainerNew .ic.menu:focus ~ .main-nav > * {
    opacity: 1;
  }
  .headerContainerNew .ic.menu:focus ~ .main-nav {
    width: 300px;
    background-color: #1f83c6;
  }
  .headerContainerNew .ic.menu:focus ~ .ic.close {
    opacity: 1;
    z-index: 21;
    outline: none;
  }
  .headerContainerNew ul.main-nav {
    z-index: 2;
    padding: 50px 0;
    position: fixed;
    right: 0px;
    top: 0px;
    width: 0px;
    background-color: #1f83c6;
    height: 100%;
    overflow: auto;
    transition-property: background, width;
    transition-duration: 0.6s;
  }
  .headerContainerNew .ic.menu:focus .line:nth-child(1) {
    transform: rotate(45deg);
  }
  .headerContainerNew .ic.menu:focus .line:nth-child(2) {
    transform: rotate(-45deg);
    margin-top: -10px;
  }
  .headerContainerNew .ic.menu:focus .line:nth-child(3) {
    transform: translateY(15px);
    opacity: 0;
  }
  .headerContainerNew .ic.menu:focus .line {
    background-color: #fff !important;
  }
  .headerContainerNew ul.main-nav > li ul.sub-menu-lists > li a {
    color: #eee;
    font-size: 14px;
  }
  .headerContainerNew .ic.menu:focus ~ ul.main-nav > li > div.sub-menu-block {
    border-left: 0px solid #ccc;
    border-right: 0px solid #ccc;
    border-bottom: 0px solid #ccc;
    position: relative;
    visibility: visible;
    opacity: 1;
  }
  .headerContainerNew .sub-menu-block {
    padding: 0 30px;
  }
  .headerContainerNew ul.main-nav > li:hover {
    background-color: transparent;
  }
  .headerContainerNew ul.main-nav > li:hover > a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
  }
}
