@import url("https://fonts.googleapis.com/css2?family=Anton&family=Antonio&family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=PT+Serif&family=Questrial&family=Qwigley&family=Raleway&family=Tiro+Gurmukhi&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Work+Sans:wght@100;200;300;400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #454545;
}

@media only screen and (max-width: 992px) {
  .headerContainerNew header.dark .ic.menu .line {
    background-color: #fff;
  }

  .headerContainerNew .ic.menu .line {
    transition: all 0.4s ease 0s;
    transform: rotate(0deg);
    transform-origin: center center;
  }

  .headerContainerNew ul.main-nav {
    z-index: 2;
    padding: 50px 0;
    position: fixed;
    right: 0px;
    top: 0px;
    width: 0px;
    background-color: #1f83c6;
    height: 100%;
    overflow: auto;
    transition-property: background, width;
    transition-duration: 0.6s;
  }
}

.slider_image {
width: 100%;
  height: 190px;
  overflow: hidden;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  background: white;
  border-radius: 5px

}

.slider_image img {
  object-fit: contain;

}

.image_size{
  max-width:100% ;
  object-fit:cover ;
  max-height:60% ;
  height:100% ;
}

.dashboard_cards {
  min-width: 150px;
  min-height: 150px;
  padding: 10px;
  border: 1px solid #ffffff;
  color: #1F83C6;
  background-color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  cursor: pointer;
}

/* styles.css */
.breadcrumb {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 1em 0;
  background: none;
}

.breadcrumb-item {
  margin-right: 0.5em;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #007bff;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
}

.breadcrumb-item.active {
  color: #6c757d;
}
